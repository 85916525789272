import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/cancellation.md"

export default function CancellationPage() {
  return (
    <LegalLayout
      description="Everything you need to know about canceling your Ready Five account."
      source={source}
      title="Cancellation Policy"
    />
  )
}
